import dynamic from 'next/dynamic';

export const MerchandisingHeroBanner = dynamic(() =>
  import('./organisms/MerchandisingHeroBanner').then(mod => mod.MerchandisingHeroBanner)
);
export const MerchandisingSmallBanner = dynamic(() =>
  import('./organisms/MerchandisingSmallBanner').then(mod => mod.MerchandisingSmallBanner)
);

export const MerchandisingTileSkinny = dynamic(() =>
  import('./organisms/MerchandisingTileSkinny').then(mod => mod.MerchandisingTileSkinny)
);

/**
 * this is non editable component, and will use in shop - AEM author
 */
export const MerchandisingSmallBannerComp = dynamic(() =>
  import('./organisms/MerchandisingSmallBanner').then(mod => mod.MerchandisingSmallBannerComponent)
);

export const MerchandisingSerpBannerComp = dynamic(() =>
  import('./organisms/MerchandisingSerpBanner').then(mod => mod.MerchandisingSerpBanner)
);

export const MerchandisingSerpBannerNonEditable = dynamic(() =>
  import('./organisms/MerchandisingSerpBanner').then(mod => mod.MerchandisingSerpBannerComponent)
);
